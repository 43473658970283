import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'Colors',
    data() {
        return {
            colors: {}
        }
    },
    components: {
        adminLayout
    },
    mounted() {
        this.getColors()
    },
    methods: {
        getColors() {
            const self = this;

            httpAxios({
                url: this.$backendUrl + '/api/v1/colors',
                method: 'GET'
            }).then(response => {
                self.colors = response.data.rows;
            })
        },
        playSound(sound) {
            if (!sound) {
                return null
            }

            let audio = new Audio(sound)
            audio.play()
        }
    }
}
